var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid content", attrs: { id: "logs" } },
    [
      _c("vue-headful", {
        attrs: {
          title: _vm.$target.toUpperCase() + " Office | Logs",
          description: "Ticket System"
        }
      }),
      _c("h1", [_vm._v("Logs")]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "2" } },
            [
              _c(
                "b-btn",
                {
                  attrs: { type: "button", variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.showAddLogEntry()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { name: "book-open" } }),
                  _vm._v(" "),
                  _c("v-icon", { attrs: { name: "plus" } }),
                  _vm._v(" New ")
                ],
                1
              ),
              _c("hr"),
              _c(
                "div",
                { staticClass: "filterlist" },
                [
                  _vm._v(" Show Newer than "),
                  _c("b-form-select", {
                    attrs: { size: "sm", options: _vm.neweropts },
                    on: { change: _vm.getLog },
                    model: {
                      value: _vm.pastmonths,
                      callback: function($$v) {
                        _vm.pastmonths = $$v
                      },
                      expression: "pastmonths"
                    }
                  }),
                  _c("p"),
                  _vm._v(" Car: "),
                  _c("b-form-select", {
                    attrs: { size: "sm", options: _vm.cars },
                    model: {
                      value: _vm.car_selected,
                      callback: function($$v) {
                        _vm.car_selected = $$v
                      },
                      expression: "car_selected"
                    }
                  }),
                  _c("p"),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Status" } },
                    [
                      _c("b-form-checkbox-group", {
                        attrs: {
                          options: _vm.carlog_statusarray,
                          switches: "",
                          stacked: ""
                        },
                        model: {
                          value: _vm.status_selected,
                          callback: function($$v) {
                            _vm.status_selected = $$v
                          },
                          expression: "status_selected"
                        }
                      })
                    ],
                    1
                  ),
                  _c("p"),
                  _c(
                    "b-form-group",
                    { attrs: { label: "Status" } },
                    [
                      _c("b-form-checkbox-group", {
                        attrs: {
                          options: _vm.carlog_categories,
                          switches: "",
                          stacked: ""
                        },
                        model: {
                          value: _vm.categories_selected,
                          callback: function($$v) {
                            _vm.categories_selected = $$v
                          },
                          expression: "categories_selected"
                        }
                      })
                    ],
                    1
                  ),
                  _c("p"),
                  _c("hr"),
                  _vm._v(" Sort by "),
                  _c("b-form-select", {
                    attrs: { size: "sm", options: _vm.sort_opts },
                    on: { change: _vm.sortresults },
                    model: {
                      value: _vm.sortby,
                      callback: function($$v) {
                        _vm.sortby = $$v
                      },
                      expression: "sortby"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("b-col", { attrs: { xs: "12", lg: "10" } }, [
            _c(
              "div",
              _vm._l(_vm.filteredData, function(entry, index) {
                return _c(
                  "b-row",
                  { key: index, staticClass: "logentry" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "leftcol", attrs: { xs: "4", lg: "3" } },
                      [
                        _c("span", { staticClass: "numberplate" }, [
                          _vm._v(" " + _vm._s(entry.CL_PLATE) + " ")
                        ]),
                        _c("p"),
                        _c("span", { staticClass: "title" }, [_vm._v("Model")]),
                        _c("span", { staticClass: "ttext" }, [
                          _vm._v(_vm._s(entry.CL_MODEL))
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "title" }, [
                          _vm._v("Status")
                        ]),
                        _c("span", { class: "ttext-" + entry.CL_STATUS }, [
                          _vm._v(
                            _vm._s(_vm.carlog_statusses_bykey[entry.CL_STATUS])
                          )
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "title" }, [_vm._v("Kat.")]),
                        _c("span", { staticClass: "ttext" }, [
                          _vm._v(
                            _vm._s(
                              _vm.carlog_categories_bykey[entry.CL_CATEGORY]
                            )
                          )
                        ])
                      ]
                    ),
                    _c(
                      "b-col",
                      { staticClass: "rightcol", attrs: { xs: "8", lg: "9" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "9" } },
                              [
                                _c(
                                  "b-row",
                                  { staticStyle: { "margin-bottom": "8px" } },
                                  [
                                    _c("b-col", [
                                      _c("span", { staticClass: "title" }, [
                                        _vm._v("Entered:")
                                      ]),
                                      _vm._v(" " + _vm._s(entry.STARTDATE))
                                    ]),
                                    _c("b-col", { staticClass: "text-right" }, [
                                      _c("span", { staticClass: "title" }, [
                                        _vm._v("Due:")
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(entry.CL_DUE) +
                                          " " +
                                          _vm._s(entry.DUEDATE)
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(" " + _vm._s(entry.CL_TEXT) + " ")
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { xs: "12", lg: "3" } },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { block: "", variant: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showSingleLog(index)
                                      }
                                    }
                                  },
                                  [_vm._v("Edit")]
                                ),
                                _c("p"),
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { block: "", variant: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showDelete(entry.CL_ID)
                                      }
                                    }
                                  },
                                  [_vm._v("Delete")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            ),
            _vm._v(" X ")
          ])
        ],
        1
      ),
      _c("LogSingle", {
        attrs: {
          logidx: this.currentLogIdx,
          logentry: this.currentLogEntry,
          carlog_statusarray: _vm.carlog_statusarray,
          carlog_categories: _vm.carlog_categories,
          cars: _vm.cars,
          logEdit: _vm.edit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }