import Vue from "vue"; //eslint-disable-line no-unused-vars
import bookingSource from "@/lib/backend/bookings";
import "@/assets/scss/bookings.scss";
import { mapState } from "vuex"; //eslint-disable-line no-unused-vars
// import tableFields from "@/lib/misc";
import "vue-awesome/icons/times-circle";

export default {
	props: {
		BO_ID: [String, Number]
	},
	watch: {
		BO_ID: {
			immediate: true,
			// eslint-disable-next-line no-unused-vars
			handler(newVal, oldVal) {
				if (newVal != null) {
					console.log("get boopking ", newVal);
					this.getBookingData(newVal);
				}
			},
			deep: true
		}
	},
	data() {
		return {
			showBlank: false,
			booking: { BO_STATUS: null },
			optionalFields: {
				BO_CLIENTID: true,
				BO_CAR: true,
				CAR_PLATE: true,
				BO_CARID: true,
				BO_CHILDSEAT_TEXT: true,
				BO_DEPOSIT: true,
				BO_LICENSE: true,
				BO_LICENSE2: true,
				BO_EXTRA: true,
				BO_FLIGHTNO: true,
				BO_FREETEXT1: true,
				BO_FREETEXT2: true,
				BO_HASVOUCHER: true,
				BO_HIREDAYS: true,
				BO_NAME: true,
				BO_NAME2: true,
				BO_EMAIL: true,
				BO_VOUCHERS: true,
				BO_HOTEL: true,
				BO_INSURANCE: true,
				BO_KIDSEAT: true,
				BO_LOCATION: true,
				BO_PHONE: true,
				BO_PICKUP: true,
				BO_PRICE: true,
				BO_PRICE_EXTRAS: true,
				BO_PRICE_SUM: true,
				BO_RETURN: true,
				BO_RETURN_TODO: true,
				BO_RISK: true,
				BO_STARTD: true,
				BO_STARTT: true,
				BO_STATUS: true,
				BO_STOPD: true,
				BO_STOPT: true,
				BO_TICKETID: true
			},
			CONTRACTID: "",
			RiskOptions: [
				"&euro; 0,--",
				"&euro; 900,--",
				"&euro; 500,--",
				"&euro; 300,--"
			],
			InsuranceOptions: ["FDW", "CDW", "CDW+FDW"]
		};
	},
	computed: {
		...mapState({
			loggedin: state => state.auth.loggedin,
			userdata: state => state.auth.userdata,
			token: state => state.auth.token
		})
	},
	methods: {
		// :BO_ID="currentBookingId"
		// :TICKET.TI_DEPT="currentDepId"
		// :PDF_URL="currentPdfUrl"

		getBookingData(id) {
			console.log("getting booking data");

			if (id > 0) {
				bookingSource
					.getContractData(id)
					.then(response => {
						this.booking = response.data;
					})
					.catch(error => {
						this.$toastr.Add({
							title: "Error", // Toast Title
							msg: error,
							position: "toast-top-center",
							type: "error"
						});
					});
			}
		},

		handleSingleBookingResponse(response) {
			this.booking = response.data;
			this.getAvailableCars();

			console.log("got booking for ", this.booking.BO_ID);
		},

		sendForm() {
			this.senddata = {
				CONTRACTID: this.CONTRACTID,
				BO_CLIENTID: this.booking.BO_CLIENTID,
				BO_CAR: this.optionalFields.BO_CAR ? this.booking.BO_CAR : "",
				BO_CARCLASS: this.optionalFields.BO_CARCLASS
					? this.booking.BO_CARCLASS
					: "",
				BO_CARID: this.booking.BO_CARID,
				BO_CHILDSEAT_TEXT: this.optionalFields.BO_CHILDSEAT_TEXT
					? this.booking.BO_CHILDSEAT_TEXT
					: "",
				BO_EXTRA: this.optionalFields.BO_EXTRA ? this.booking.BO_EXTRA : "",
				BO_FLIGHTNO: this.optionalFields.BO_FLIGHTNO
					? this.booking.BO_FLIGHTNO
					: "",
				BO_VOUCHERS: this.optionalFields.BO_VOUCHERS
					? this.booking.CL_VOUCHERS
					: "",
				BO_HIREDAYS: this.optionalFields.BO_HIREDAYS
					? this.booking.BO_HIREDAYS
					: "",
				BO_NAME: this.optionalFields.BO_NAME
					? this.booking.CL_FIRSTNAME + " " + this.booking.CL_LASTNAME
					: "",
				BO_NAME2:
					this.optionalFields.BO_NAME2 && this.booking.CL_FIRSTNAME2
						? this.booking.CL_FIRSTNAME2 + " " + this.booking.CL_LASTNAME2
						: "",
				BO_LICENSE: this.optionalFields.BO_LICENSE
					? this.booking.CL_DRIVERLICENSE
					: "",
				BO_LICENSE2: this.optionalFields.BO_LICENSE2
					? this.booking.CL_DRIVERLICENSE2
					: "",
				BO_EMAIL: this.booking.CL_EMAIL,
				BO_PHONE: this.optionalFields.BO_PHONE ? this.booking.CL_MOBILE : "",
				BO_HOTEL: this.optionalFields.BO_HOTEL ? this.booking.BO_HOTEL : "",
				BO_INSURANCE: this.optionalFields.BO_INSURANCE
					? this.booking.BO_INSURANCE
					: "",
				BO_LOCATION: this.optionalFields.BO_LOCATION
					? this.booking.BO_LOCATION
					: "",
				BO_PICKUP: this.optionalFields.BO_PICKUP ? this.booking.BO_PICKUP : "",
				BO_PRICE: this.optionalFields.BO_PRICE ? this.booking.BO_PRICE : "",
				// BO_PRICE_EXTRAS: (this.optionalFields.BO_PRICE_EXTRAS) ? this.booking.BO_PRICE_EXTRAS : "",
				BO_PRICE_SUM: this.optionalFields.BO_PRICE_SUM
					? this.booking.BO_PRICE_SUM
					: "",
				BO_RETURN: this.optionalFields.BO_RETURN ? this.booking.BO_RETURN : "",
				BO_STARTD: this.optionalFields.BO_STARTD ? this.booking.BO_STARTD : "",
				BO_STARTT: this.optionalFields.BO_STARTT ? this.booking.BO_STARTT : "",
				BO_STATUS: this.optionalFields.BO_STATUS ? this.booking.BO_STATUS : "",
				BO_STOPD: this.optionalFields.BO_STOPD ? this.booking.BO_STOPD : "",
				BO_STOPT: this.optionalFields.BO_STOPT ? this.booking.BO_STOPT : "",
				CAR_PLATE: this.optionalFields.CAR_PLATE ? this.booking.CAR_PLATE : "",
				BO_TICKETID: this.booking.BO_TICKETID,
				blankForm: this.showBlank
			};

			// console.log(this.senddata);
			// return true;
			if (parseInt(this.booking.BO_ID) > 0) {
				let dlname = "Contract_" + this.booking.BO_TICKETID + ".pdf";

				this.senddata["BO_ID"] = this.booking.BO_ID;
				bookingSource
					.getContract(this.senddata)
					.then(response => {
						if (response.Error) {
							this.$toastr.Add({
								title: "Error", // Toast Title
								msg: "Could not download PDF " + dlname,
								position: "toast-top-center",
								type: "error"
							});
							return false;
						}
						let blob = new Blob([response.data], { type: "application/pdf" });
						let link = document.createElement("a");
						link.href = window.URL.createObjectURL(blob);
						link.download = dlname;
						link.click();
					})
					.catch(error => {
						this.$toastr.Add({
							title: "Error", // Toast Title
							msg: "Could not download PDF " + dlname + "Error " + error,
							position: "toast-top-center",
							type: "error"
						});
					});
			}
		}
	}
};
